import React, { useRef, useEffect } from "react";
import bgVideo from "../assets/alea.mp4";

const Video = () => {

  const videoRef = useRef();
  const setUpVideo =()=>{
    videoRef.current.play()
  }
 
  useEffect(() => {
    setUpVideo()
}, []);

  return (
    <video ref={videoRef} src={bgVideo} type="video/mp4"  autoPlay={true} loop={true} preload={true}
    muted="muted" id="siteVideo" />
  );
};

export default Video;
