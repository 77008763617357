import React from "react";
import Media from "react-media";
import Video from "./components/Video"
import SubscribePage from './components/SubscribePage'
import logo_desktop from "./assets/logo_desktop.png";
import logo_mobile from "./assets/logo_mobile.png";
import photo_mobile from "./assets/photo_mobile.png";

function App() {

  return (
    <div>
      <Media
        query="(min-width: 527px)"
        render={() => (
          <Video />
        )}
      />
      <div className="wrapper">
        <div className="mask"></div>
        <main>
          <header className="app-header">
            <div className="logo">
              <img alt="Aeléa logo" src={logo_desktop} />
            </div>
            <div className="logo_mobile">
              <img alt="Aeléa logo" src={logo_mobile} />
            </div>
          </header>
          <section>
            <div className="titles">
              <h1 className="main-tilte">High-Performance Organic Skincare</h1>
              <h2 className="sub-tilte">For the 9 months and beyond!</h2>
            </div>
            <img
              alt="Aeléa background"
              className="bg-mobile"
              src={photo_mobile}
            />
          <SubscribePage/>
          </section>
          
        </main>
        <footer>
          <h6>@Aeléa Organics 2020</h6>
        </footer>
      </div>
    </div>
  );
}

export default App;
