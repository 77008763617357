/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

class SubscribePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailValue: '',
        };
        this.form = React.createRef();
    }

    handleSubmit=(e)=>{
        e.preventDefault()
        this.form.submit()
    }

    render() {
        return (
            <form ref={f => (this.form = f)} action="https://aeleaorganics.us18.list-manage.com/subscribe/post?u=61ec399fc188e64d9305554d9&amp;id=10afb64296" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                <article className="news-letter-join">
                    Join our newsletter & get the chance to <br />
                    <span>WIN</span> 1 of 5 product sets when we launch!
            </article>
                <div className="news-letter">
                    <input
                        type="email"
                        name="EMAIL"
                        id="MERGE0"
                        value={this.state.emailValue}
                        onChange={(e) => { this.setState({ emailValue: e.target.value }); }}
                        autoCapitalize="off"
                        autoCorrect="off"
                        placeholder="email address"
                    />
                    <a onClick={this.handleSubmit}>
                        <i className="arrow right"></i>
                    </a>
                </div>
            </form>
        )
    }
}

export default SubscribePage